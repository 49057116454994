<template>
  <div>
    <b-row v-if="compareAtPrice !== false" class="mobile-hide">
      <b-col class="sale-info bold">
        SALE
      </b-col>
    </b-row>
    <b-row :class="{ sale: compareAtPrice !== false }" class="mobile-hide">
      <b-col>
        <span class="bold">{{ product.vendor }}</span> {{ product.title }}
      </b-col>
      <b-col>
        <div v-if="price !== false" class="price">
          <span class="compareAtPrice" v-if="compareAtPrice">
            {{ compareAtPrice }}
          </span>
          <span class="regularPrice">{{ price }}</span>
        </div>
      </b-col>
    </b-row>
    <b-row class="color-row">
      <b-col>
        <div
          class="option-group"
          v-for="(prop, index) in product.options"
          :key="prop.id"
          :class="prop.name.toLowerCase()"
        >
          <div class="option-name">
            {{ prop.name }}
          </div>
          <div class="options">
            <product-option
              :index="index"
              v-model="selectedOptions[prop.name]"
              :name="prop.name"
              size="lg"
              :options="
                prop.values.map((option) => {
                  return { text: option.value, value: option.value }
                })
              "
              @change="onChange"
            />
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row class="mobile-hide add-to-card-row">
      <b-col>
        <template v-if="selectedVariant && selectedVariant.available">
          <b-button variant="add-to-cart" size="lg" block @click="addToCart">
            {{ $t('shop.add') }}
          </b-button>
        </template>
        <template v-else>
          <b-button variant="add-to-cart" size="lg" block disabled>
            {{ $t('shop.sold') }}
          </b-button>
        </template>
      </b-col>
    </b-row>
    <div class="mobile-add-to-cart">
      <b-container>
        <b-row :class="{ sale: compareAtPrice !== false }">
          <b-col>
            <span class="bold">{{ product.vendor }}</span> {{ product.title }}
            <div v-if="price !== false" class="price">
              <span class="compareAtPrice" v-if="compareAtPrice">
                {{ compareAtPrice }}
              </span>
              <span class="regularPrice">{{ price }}</span>
            </div>
          </b-col>
        </b-row>
        <b-row class="oversize-columns">
          <b-col>
            <button
              class="btn-show-sizes small"
              @click="mobileSizes = !mobileSizes"
            >
              Sizes
            </button>
          </b-col>
          <b-col>
            <template v-if="selectedVariant && selectedVariant.available">
              <b-button
                variant="add-to-cart small"
                size="lg"
                block
                @click="addToCart"
              >
                {{ $t('shop.addShort') }}
              </b-button>
            </template>
            <template v-else>
              <b-button variant="add-to-cart small" size="lg" block disabled>
                {{ $t('shop.sold') }}
              </b-button>
            </template>
          </b-col>
        </b-row>
        <b-row v-if="mobileSizes">
          <b-col>
            <div
              class="option-group"
              v-for="(prop, index) in mobileOptions"
              :key="prop.id"
            >
              <div class="options">
                <product-option
                  :index="index"
                  v-model="selectedOptions[prop.name]"
                  :name="prop.name"
                  size="lg"
                  :options="
                    prop.values.map((option) => {
                      return { text: option.value, value: option.value }
                    })
                  "
                  @change="onChange"
                />
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import ProductOption from '~/components/product/ProductOption'
if (process.client) {
  gsap.registerPlugin(ScrollTrigger)
}

export default {
  components: {
    ProductOption
  },
  props: {
    product: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      selectedOptions: null,
      quantity: 1,
      mobileSizes: false
    }
  },
  computed: {
    mobileOptions() {
      const filterdOptions = []

      for (let i = 0; i < this.product.options.length; i++) {
        if (this.product.options[i].name === 'Size') {
          filterdOptions.push(this.product.options[i])
        }
      }

      return filterdOptions
    },
    price() {
      if (this.selectedVariant) {
        return new Intl.NumberFormat('de-DE', {
          style: 'currency',
          currency: 'EUR'
        }).format(this.selectedVariant.price)
      } else {
        return false
      }
    },
    compareAtPrice() {
      if (this.selectedVariant && this.selectedVariant.compareAtPrice) {
        return new Intl.NumberFormat('de-DE', {
          style: 'currency',
          currency: 'EUR'
        }).format(this.selectedVariant.compareAtPrice)
      } else {
        return false
      }
    },
    selectedVariant() {
      return this.$shopify.product.helpers.variantForOptions(
        this.product,
        this.selectedOptions
      )
    }
  },
  created() {
    let initialVariant = 0
    if (this.$route.hash && this.$route.hash.indexOf('#variant-') === 0) {
      initialVariant = this.$route.hash.replace('#variant-', '')
    }
    this.selectedOptions = this.product.variants[
      initialVariant
    ].selectedOptions.reduce(
      (acc, cur) => ({ ...acc, [cur.name]: cur.value }),
      {}
    )
    this.onChange()
  },
  mounted() {
    this.$nextTick(() => {
      this.initScrollTrigger()
    })
  },
  methods: {
    initScrollTrigger() {
      const el = document.querySelector('.mobile-add-to-cart')
      this.thisOffset = this.topOffset
      this.scrollTr = ScrollTrigger.create({
        id: 'mc',
        trigger: 'body',
        endTrigger: '.footer-section',
        end: 'top top',
        onLeave: ({ progress, direction, isActive }) => el.classList.add('end'),
        onEnterBack: ({ progress, direction, isActive }) =>
          el.classList.remove('end')
      })
    },
    addToCart() {
      this.$store.commit('ui/openCart')
      const lineItemsToAdd = [
        {
          variantId: this.selectedVariant.id,
          quantity: parseInt(this.quantity, 10)
        }
      ]
      this.$store.dispatch('shop/addLineItems', lineItemsToAdd)
    },
    onChange() {
      if (this.selectedVariant) {
        this.$emit('updateVariant', this.selectedVariant)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.add-to-card-row {
  margin-top: 32px;
}
.color-row .col {
  padding: 0 0.5rem;
}

.mobile-hide {
  @include media-breakpoint-down(sm) {
    display: none;
  }
}
.sale-info {
  color: #ed6f72;
}
.price {
  text-align: right;
}
.sale .compareAtPrice {
  margin-right: 8px;
  text-decoration: line-through;
}
.sale .regularPrice {
  color: red;
}

.option-group {
  border-top: 1px solid #ccc;
  display: flex;
  flex-direction: row;
  padding: 20px 0 16px;
  &:first-child {
    margin-top: 16px;
  }
  .option-name,
  .options {
    width: 50%;
  }
  @include media-breakpoint-down(sm) {
    &.size {
      display: none;
    }
  }
}
.mobile-add-to-cart {
  background: white;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  display: none;
  padding-top: 20px;
  padding-bottom: 19px;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translate(0, 0);
  transition-property: transform;
  transition-duration: $shift-animation-duration;
  transition-timing-function: $shift-animation-easing;
  z-index: 99999;
  .shift-left & {
    transform: translate(100%, 0);
  }
  @include media-breakpoint-down(sm) {
    display: block;
  }
  &.end {
    transform: translate(0, 100%);
    .shift-left & {
      transform: translate(100%, 100%);
    }
  }
  .price {
    margin-bottom: 16px;
    text-align: left;
  }
  .oversize-columns {
    .col {
      &:first-child {
        padding-right: 4px;
      }
      &:last-child {
        padding-left: 4px;
      }
    }
  }
  .option-group {
    border: none;
    .options {
      width: 100%;
    }
  }
}
</style>
