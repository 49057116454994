<template>
  <div class="pagination-wrapper">
    <div class="pagination" :class="pagClass"></div>
  </div>
</template>

<script>
export default {
  name: 'Paginations',
  props: {
    pagClass: {
      type: String,
      default: () => {}
    }
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss">
.pagination-wrapper {
  display: block;
  z-index: 50;
  position: relative;

  .pagination {
    position: absolute;
    padding: 36px 4px;
    right: 50%;
    transform: translateX(50%);
    display: inline-block;

    @include media-breakpoint-up(md) {
      right: 0;
      transform: translateX(0);
    }

    .swiper-pagination-bullet {
      width: 9px;
      height: 9px;
      background: transparent;
      border: 1px solid rgba($black, $alpha: 0.5);
      opacity: 1;
      margin: 0 6px !important;
      outline: none;

      @include media-breakpoint-up(md) {
        margin: 0 11px !important;
      }
      &:first-child {
        margin-left: 0 !important;
      }
      &:last-child {
        margin-right: 0 !important;
      }
    }

    .swiper-pagination-bullet-active {
      background: $black;
    }
  }
}
</style>
