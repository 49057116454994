<template>
  <base-section class="section product-section">
    <div class="back-link">
      <a @click.prevent="hasHistory() ? $router.go(-1) : $router.push('/')"
        ><ArrowLeft />{{ $t('links.back') }}</a
      >
    </div>
    <b-container>
      <b-row v-win-resize="onResize">
        <b-col cols="12" md="1" class="images thumbnails" sticky-container>
          <div
            id="fixed-thumbnails-wrapper"
            ref="leftContent"
            v-sticky="isSticky"
            sticky-offset="leftOffset"
          >
            <b-nav v-b-scrollspy="200">
              <b-nav-item
                v-for="(image, index) in images"
                :key="image.id"
                class="thumb"
                @click.prevent="jumpTo('image' + index)"
                :data-jumpto="'image' + index"
                :href="'#image' + index"
              >
                <shopify-image :image="image" />
              </b-nav-item>
            </b-nav>
          </div>
        </b-col>
        <b-col cols="12" md="5" class="images fullsize">
          <client-only>
            <div v-if="!isMobile" id="nav-scroller">
              <div
                v-for="(image, index) in images"
                :key="image.id"
                class="full"
                :data-jumpmark="'image' + index"
                :id="'image' + index"
              >
                <hover-image :image="image" :scale="1.5"></hover-image>
              </div>
            </div>
            <div v-if="isMobile">
              <swiper :options="swiperOptions">
                <swiper-slide v-for="image in images" :key="image.id">
                  <div class="size-wrapper">
                    <shopify-image :image="image" class="full" lightbox />
                  </div>
                </swiper-slide>
              </swiper>
              <paginations pag-class="pagination-images" />
            </div>
          </client-only>
        </b-col>
        <b-col
          class="mt-4 mt-md-0"
          cols="12"
          md="5"
          lg="4"
          offset-md="1"
          sticky-container
        >
          <div
            id="add-to-cart-wrapper"
            ref="rightContent"
            v-sticky="isSticky"
            sticky-offset="rightOffset"
          >
            <add-to-cart
              ref="addToCart"
              :product="product"
              @updateVariant="onVariantUpdate"
            />
            <product-tabs :tabs="tabs" />
          </div>
        </b-col>
      </b-row>
    </b-container>
  </base-section>
</template>

<script>
import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'

import ArrowLeft from '~/assets/svg/arrow-left.svg?inline'
import AddToCart from '~/components/AddToCart'
import ProductTabs from '~/components/ProductTabs'
import Paginations from '~/components/slider/Paginations'
if (process.client) {
  gsap.registerPlugin(ScrollToPlugin)
}

export default {
  components: {
    AddToCart,
    ProductTabs,
    Paginations,
    ArrowLeft
  },
  props: {
    product: Object
  },
  data() {
    return {
      detailSplitTag: 'h3',
      leftOffset: {
        top: 76
      },
      rightOffset: {
        top: 76
      },
      isMobile: false,
      isSticky: false,
      images: [],
      swiperOptions: {
        pagination: {
          el: '.pagination-images',
          clickable: true
        }
      }
    }
  },
  computed: {
    tabs() {
      const tagData = []

      if (this.product.descriptionHtml.includes(`<${this.detailSplitTag}>`)) {
        const tmpData = this.product.descriptionHtml.split(
          `<${this.detailSplitTag}>`
        )

        for (const i in tmpData) {
          if (i !== '0') {
            const innerSplit = tmpData[i].split(`</${this.detailSplitTag}>`)
            tagData.push({
              nav: innerSplit[0],
              content: innerSplit[1]
            })
          }
        }
      } else {
        tagData.push({
          nav: '',
          content: this.product.descriptionHtml
        })
      }
      return tagData
    }
  },
  methods: {
    hasHistory() {
      return window.history.length > 2
    },
    onVariantUpdate(selectedVariant) {
      let newImages = []
      // Simply show product images if variant has no image assigned
      if (!selectedVariant.image) {
        newImages = this.product.images
      } else {
        const imageMarker = this._.chain(this.product.variants)
          .map('image')
          .map('id')
          .uniq()
          .value()

        let foundStart = false
        let foundEnd = false
        this.product.images.forEach((image, index) => {
          if (!foundStart && image.id === selectedVariant.image.id) {
            foundStart = true
          } else if (foundStart && imageMarker.includes(image.id)) {
            foundEnd = true
          }

          if (foundStart && !foundEnd) {
            newImages.push(image)
          }
        })
      }
      this.images = newImages
    },
    onResize() {
      // console.log(this.$router)
      this.isMobile = process.client && window.innerWidth < 1024
      this.isSticky =
        !this.isMobile &&
        this.$refs.leftContent.offsetHeight + this.leftOffset.top <
          window.innerHeight &&
        this.$refs.rightContent.offsetHeight + this.rightOffset.top <
          window.innerHeight
    },
    jumpTo(el) {
      const offset = document
        .querySelector('.fullsize img')
        .getBoundingClientRect().top
      const to = document
        .querySelector('[data-jumpmark=' + el + ']')
        .getBoundingClientRect().top
      gsap.to(window, {
        duration: 0.5,
        scrollTo: to - offset
      })
    }
  }
}
</script>

<style lang="scss">
.product-section {
  padding-bottom: 4vw;
  min-height: 100vh;

  .back-link {
    cursor: pointer;
    transition: 0.4s margin ease-in-out;
    margin: 20px 22px 0;
    @include media-breakpoint-up(sm) {
      margin: 20px 50px 0;

      .activeOffset & {
        margin: 60px 50px 0;
      }
    }
    a {
      display: inline-block;
      text-decoration: none;

      font-size: 13px;
      line-height: 19px;
      @include media-breakpoint-up(md) {
        font-size: 16px;
      }

      &:hover {
        text-decoration: none;
        svg {
          @include media-breakpoint-up(md) {
            transform: translate(-5px, -1px);
          }
        }
      }
      svg {
        width: 10px;
        display: inline-block;
        margin-right: 8px;
        opacity: 1;
        transform: translate(0, -1px);
        @include media-breakpoint-up(md) {
          transition: transform ease 0.4s;
        }
      }
    }
  }

  #fixed-thumbnails-wrapper,
  .fullsize {
    padding-top: 4vw;
  }
  #add-to-cart-wrapper {
    padding-top: 60px;
    @include media-breakpoint-up(md) {
      padding-top: 8vw;
    }
  }
  .images {
    .size-wrapper {
      max-width: 450px;
      margin: auto;
    }

    .media-element {
      position: relative;
      width: 100%;
      padding-top: 126.25%;
      overflow: hidden;

      @include media-breakpoint-up(md) {
        padding-top: 132.42574257%;
      }

      img {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        width: 100%;
        object-fit: cover;
      }
    }

    .pz-zoom-button {
      display: none;
    }

    ul {
      margin: 0;
      padding: 0;
      .thumb {
        width: 100%;
        margin: 0;
        padding: 0;
        cursor: pointer;
        margin-bottom: 8px;
        &:last-child {
          margin-bottom: 0;
        }
        a {
          margin: 0;
          padding: 0;
          opacity: 0.6;
          transition: opacity 200ms;
          &.active {
            opacity: 1;
          }
        }
      }
    }
    .full {
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
      img {
        // width: 100%;
        // margin: 0 auto;
        // display: block;
        // max-width: 450px;
        @include media-breakpoint-up(md) {
          max-width: unset;
          margin: unset;
        }
      }
    }
    @include media-breakpoint-down(md) {
      &:first-child {
        display: none;
      }
    }
  }
}
.pswp__bg {
  background: white;
  background-color: white;
}
</style>
