<template>
  <div class="product-tabs">
    <div v-if="hasTabs" class="tabs-nav">
      <span
        v-for="(tab, index) in tabs"
        :key="tab.nav"
        :class="{ active: index == active_index }"
        @click="toggleTab(index)"
        v-html="tab.nav"
      ></span>
    </div>
    <div class="tabs-content">
      <div v-for="(tab, index) in tabs" :key="tab.nav">
        <span
          @click="toggleTab(index)"
          class="tab-headline"
          :class="{ active: index == active_index }"
          >{{ tab.nav }}</span
        >
        <div
          v-html="tab.content"
          class="tab-content"
          :class="{ active: index == active_index }"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      active_index: 0
    }
  },
  computed: {
    hasTabs() {
      return this.tabs.length > 0 && this.tabs[0].nav !== ''
    }
  },
  methods: {
    toggleTab(index) {
      this.active_index = index
    }
  }
}
</script>

<style lang="scss">
$borderColor: #ccc;
.product-tabs {
  margin-top: 44px;
  @include media-breakpoint-down(sm) {
    margin-top: 0;
  }
  .tabs-nav {
    display: none;
    @include media-breakpoint-up(sm) {
      display: block;
      > span {
        cursor: pointer;
        display: inline-block;
        margin-right: 24px;
        overflow: hidden;
        position: relative;
        &:after {
          background-color: #1c0f0c;
          content: '';
          height: 1px;
          opacity: 0;
          position: absolute;
          left: 0;
          bottom: 0;
          transition: opacity 0.24s;
          width: 100%;
        }
        &.active {
          font-weight: 500;
          &:after {
            opacity: 1;
          }
        }
      }
    }
  }
  .tabs-content {
    @include media-breakpoint-up(md) {
      margin-top: 24px;
    }
    .tab-headline {
      background-image: url("data:image/svg+xml,%3Csvg width='17px' height='8px' viewBox='0 0 17 8' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Layout' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Android-Copy-26' transform='translate(-324.000000, -952.000000)' stroke='%23979797'%3E%3Cg id='icon/plus_small' transform='translate(325.000000, 948.000000)'%3E%3Cpolyline id='Path' transform='translate(7.500000, 4.500000) rotate(-45.000000) translate(-7.500000, -4.500000) ' points='12.5 9.5 2.5 9.5 2.5 -0.5'%3E%3C/polyline%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: right center;
      border-top: 1px solid $borderColor;
      display: block;
      padding: 20px 0 16px 0;
      @include media-breakpoint-up(sm) {
        display: none;
      }
      &.active {
        background-image: url("data:image/svg+xml,%3Csvg width='15px' height='1px' viewBox='0 0 15 1' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Layout' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Android-Copy-26' transform='translate(-322.000000, -648.000000)' stroke='%23979797'%3E%3Cg id='icon/minus_small' transform='translate(322.000000, 647.000000)'%3E%3Cline x1='0' y1='1.5' x2='15' y2='1.5' id='Path-13'%3E%3C/line%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      }
    }
    .tab-content {
      display: none;
      margin: 12px 0 32px 0;
      @include media-breakpoint-up(sm) {
        margin: 0;
      }
      &.active {
        display: block;
      }
    }
  }
}
</style>
